import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { ExternalLink } from '../../components';
import classNames from 'classnames';

import css from './SectionLocations.css';

import locationImage1 from './images/location1.png';

const SectionLocations = props => {
  const { rootClassName, className, onAuthenticate } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <ExternalLink
          key="forCustomer"
          href={'https://ownchecks.ownkicks.com/'}
          title={'For Customer'}
        >
          <FormattedMessage id="SectionLocations.title1" />
        </ExternalLink>
      </div>
      <div className={css.locations}>
        <FormattedMessage
          id="SectionLocations.text1"
          values={{
            tryUsToday: (
              <ExternalLink href='https://authentication.resaleforce.com/'>
                <FormattedMessage id="SectionLocations.tryUsToday" />
              </ExternalLink>
            )
          }}
        />
      </div>
      <div className={css.title}>
        <FormattedMessage id="SectionLocations.title2" />
      </div>
      <div className={css.locations}>
        <FormattedMessage
          id="SectionLocations.text2"
          values={{
            requestDemo : (
              <span className={css.authenticateLink} onClick={onAuthenticate} >
                <FormattedMessage id="TopbarDesktop.authenticate" />
              </span>
            )
          }}
        />
      </div>
      <iframe
        className={css.embeddedVideo}
        width="100%"
        height="auto"
        src="https://www.youtube.com/embed/8zl9tQ6eEVo"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen>
      </iframe>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
